var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring-tunnel";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"9e5d396361490cd469e29711bd96ed4c1cd2ea0e"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import {
  init,
  BrowserTracing,
  BrowserOptions,
  addGlobalEventProcessor,
} from '@sentry/nextjs'
import { PURPOSITY_ENV, SENTRY_DSN } from 'constants.mjs'
import { isCheckly, ZodProcessor } from './sentry.common'

init({
  dsn: SENTRY_DSN,
  environment: PURPOSITY_ENV,

  tracesSampleRate: 1.0,

  integrations: [
    new BrowserTracing({
      tracePropagationTargets: [
        /** MATCH EVERYTHING */
        /./,
      ],
    }),
  ],

  beforeSend(event, _hint) {
    if (isCheckly(event)) return null
    return event
  },
  beforeSendTransaction(event, hint) {
    if (isCheckly(event)) return null
    return event
  },
} satisfies BrowserOptions)

addGlobalEventProcessor(ZodProcessor)
