/* eslint-disable no-process-env */
import * as z from 'zod'

const UNIVERSAL_VERCEL_ENV =
  process.env.VERCEL_ENV ?? process.env.NEXT_PUBLIC_VERCEL_ENV
const UNIVERSAL_VERCEL_URL =
  process.env.VERCEL_URL ?? process.env.NEXT_PUBLIC_VERCEL_URL

export const PRODUCTION_HOST = 'taxes.purposity.app'
export const DEV_PORT = process.env.PORT ?? 3090
export const SENTRY_DSN =
  'https://ea881d09167e42e5a1d517c9b5bc9faa@o546790.ingest.sentry.io/4504715393761280'

export const Environment = z.enum([
  'development',
  'preview',
  'production',
  'test',
  'local',
])

export const PURPOSITY_ENV = Environment.parse(
  UNIVERSAL_VERCEL_ENV ||
    (process.env.NODE_ENV === 'production' ? 'local' : process.env.NODE_ENV) ||
    'development'
)

export const DEPLOYMENT_URL = new URL(
  PURPOSITY_ENV === 'production'
    ? `https://${PRODUCTION_HOST}`
    : PURPOSITY_ENV === 'preview'
    ? `https://${UNIVERSAL_VERCEL_URL}`
    : `http://localhost:${DEV_PORT}`
)
